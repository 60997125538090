@import 'styles/import.scss';

.checkbox {
  display: flex;
  align-items: center;
  input {
    appearance: none;
    width: 16px;
    height: 16px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    margin-right: 12px;
    flex-shrink: 0;
    &:hover {
      border-color: $green;
    }
    &:checked:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      margin-top: -1px;
      margin-left: -1px;

      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1519_6588' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1519_6588)'%3E%3Cpath d='M8.33329 13.375L5.29163 10.3333L6.16663 9.45833L8.33329 11.625L13.8333 6.125L14.7083 7L8.33329 13.375Z' fill='%232FBD77'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}
