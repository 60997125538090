@import 'styles/import.scss';

.serviceSubContainer {
  padding: 64px 40px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid $grey25;
    margin: 0 -40px;
    position: absolute;
    bottom: 0;
    width: 100vw;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    border-right: 1px solid $grey25;
    &:nth-child(3n) {
      border-right: 0;
    }
    &:after {
      display: none;
    }
    &::before,
    &:after {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 64px 80px;
  }
}

.servicesSubWrapper {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.title {
  @include text-xl;
  color: $blue_dark;
}

.text {
  @include text-sm;
  color: $blue_dark;
  @include media-breakpoint-up(md) {
    margin-top: auto;
  }
}

.serviceSubWrapper {
  &::before,
  &::after {
    display: none;
  }
  &:first-child,
  &:nth-child(2) {
    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid $grey25;
    }
  }
  &:hover {
    background-color: $grey1;
    :local(.title) {
      color: $blue;
    }
  }
  @include media-breakpoint-up(md) {
    &:first-child {
      &::after {
        display: none;
      }
    }
  }
}
