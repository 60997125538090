@import 'styles/import.scss';

.text {
  @include text-md;

  background-color: $grey1;
  padding: 80px 40px;
  color: $blue_dark;

  @include tn(opacity, 200ms, 0s, ease-in);
  opacity: 1;

  &.fadeIn {
    opacity: 1;
  }

  &.fadeOut {
    opacity: 0;
  }

  p {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 48px;
  }
  h6 {
    @include text-xl;
    + p {
      margin-bottom: 48px;
    }
  }

  a {
    display: block;
  }
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(7);
    padding: 80px 120px;
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
  }
}

.labelWrapper {
  border-bottom: 1px solid $grey25;
}

.tabs {
  @include media-breakpoint-up(md) {
    @include make-col(6);
    border-right: 1px solid $grey25;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(5);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(4);
  }
}

.listWrapper {
  @include media-breakpoint-down(sm) {
    margin: 0 40px;
    div:last-child {
      border-bottom: 0;
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  @include text-lg;
  padding: 40px;
  border-bottom: 1px solid $grey25;
  cursor: pointer;
  background: $white;
  @include tn(background, 0.5s, ease-in-out, 0s);
  &:last-child {
    border-bottom: 0;
  }
  &.isactive,
  &:hover {
    background: $grey1;
    color: $blue;
  }
  &:hover {
    svg {
      position: relative;
      animation: arrowMove 1s ease-in-out;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 40px 80px;
  }
}

.contacts {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row-reverse;
  }
}

@keyframes arrowMove {
  0% {
    left: 2px;
  }

  100% {
    left: 0px;
  }
}
