@import 'styles/import.scss';

.imageWrapper {
  @include media-breakpoint-up(lg) {
    @include make-col(6);
    border-right: 1px solid $grey25;
  }
}

.imageAnimationWrapper {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}
