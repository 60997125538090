@import 'styles/import.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 80px 0;
  @include media-breakpoint-up(md) {
    padding: 80px 40px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 36px;
    .post {
      width: calc(50% - 18px);
    }
  }
  @include media-breakpoint-up(lg) {
    gap: 48px 24px;
    padding: 120px 80px;
    .post {
      width: calc(33.3333% - 16px);
    }
  }
}

.image {
  margin-bottom: 32px;
  cursor: pointer;
}

.subtitle {
  margin-bottom: 8px;
  color: $grey200;
  @include text-xs;
  i {
    background: $grey200;
  }
}

.title {
  color: $blue_light;
  @include tn(color, 0.5s, 0s, ease-in-out);
  @include text-xl;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:hover {
    color: $blue;
  }
}

.date {
  @include text-sm;
  margin-bottom: 8px;
  color: $blue_dark;
}
