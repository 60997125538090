@import 'styles/import.scss';

.select {
  margin-bottom: 32px;
  &:global(.has--full-width) {
    width: 100%;
  }
  &.isError {
    color: $red_error;
    label,
    label::after {
      color: $red_error;
    }
    select {
      border-color: $red_error;
    }
  }
  select {
    appearance: none;
  }

  @include media-breakpoint-up(md) {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    padding: 0 16px;
  }
}

.selectWrapper {
  position: relative;

  &:after {
    content: '';
    background-size: cover;
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1400_5005' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1400_5005)'%3E%3Cpath d='M12 15.0492L6.35001 9.37422L7.40001 8.32422L12 12.9242L16.6 8.32422L17.65 9.37422L12 15.0492Z' fill='%232FBD77'/%3E%3C/g%3E%3C/svg%3E");
  }
  &:hover {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1400_5005' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1400_5005)'%3E%3Cpath d='M12 15.0492L6.35001 9.37422L7.40001 8.32422L12 12.9242L16.6 8.32422L17.65 9.37422L12 15.0492Z' fill='%23147b48'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

.container {
  @include border-underline;
  &:hover {
    label,
    label::after {
      @include tn(color, 0.5s, 0s, ease-in-out);
      color: $green_dark;
    }
  }
}
