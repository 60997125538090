@import 'styles/import.scss';

.imageWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(6);
    border-left: 1px solid $grey25;
    position: relative;
    width: 100%;
    > span {
      max-height: 400px;
      height: 100% !important;
    }
  }
}

.textWrapper {
  padding: 64px 40px;
  background: $grey1;
  min-height: 300px;
  border-bottom: 1px solid $grey25;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::before,
  &::after {
    display: none;
  }
  @include media-breakpoint-up(md) {
    padding: 64px 80px;
    border-bottom: 0;
    @include make-col(6);
    &:hover {
      a {
        color: $blue_light;
        svg {
          left: 8px;
          path {
            fill: $blue_light !important;
          }
          rect {
            stroke: $blue_light !important;
          }
        }
      }
    }
  }
}

.card {
  @include media-breakpoint-up(md) {
    display: flex;
    max-height: 400px;
  }
}

.imageLink {
  &::after {
    content: '';
    border-left: 1px solid $grey25;
    border-right: 1px solid $grey25;
    height: 80px;
    display: block;
    max-width: $container-max-width;
    margin: 0 auto;
  }
  &:last-child {
    border-top: 1px solid $grey25;
    padding-bottom: 0;
    &::after {
      height: 120px;
    }
  }
  @include media-breakpoint-up(md) {
    &:nth-child(odd) {
      :local(.card) {
        flex-direction: row-reverse;
      }
      :local(.imageWrapper) {
        border-right: 1px solid $grey25;
        border-left: 0;
      }
    }
  }
}

.button {
  justify-content: space-between !important;
  @include text-xl;
  width: 100%;
}

.text {
  @include text-sm;
  color: $blue_dark;
}
