@import 'styles/import.scss';

.text {
  input {
    padding: 12px 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    height: 50px;
    &:focus,
    &:active {
      outline: 0;
    }
  }
}
