@import 'styles/import.scss';

.imageTextWrapper {
  @include media-breakpoint-up(md) {
    display: flex;
    padding-top: 120px;
    position: relative;
    &::after,
    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $grey25;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    &:after {
      bottom: 0;
    }
    &.onlyChild {
      padding-top: 0;
      &::before {
        display: none;
      }
      &:last-child {
        padding-bottom: 0;
      }
      &:after {
        display: none;
      }
      :local(.imageContainer) {
        border-top: 0;
      }
    }
    &:last-child {
      padding-bottom: 120px;
      &:after {
        bottom: 120px;
      }
    }
    &.removePaddingBottom {
      &:after,
      &::before {
        display: none;
      }
      &:last-child {
        padding-bottom: 0;
      }
      :local(.imageContainer) {
        border-bottom: 0;
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      :local(.imageContainer) {
        border-left: 0;
        border-right: 1px solid $grey25;
      }
    }
  }
}

.imageContainer {
  border-top: 1px solid $grey25;
  margin: 0 -40px;

  @include media-breakpoint-up(md) {
    @include make-col(6);
    margin: 0;
    border-top: 0;
    border-left: 1px solid $grey25;
    > span {
      height: 100% !important;
    }
  }
}

.button {
  margin-top: 48px;
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
}

.title {
  @include heading-6;
  margin-bottom: 48px;
  color: $blue_dark;
}

.label {
  @include text-md;
  color: $blue_light;
  margin-bottom: 8px;
}

.text {
  color: $blue_dark;
  p {
    margin-bottom: 0;
    + h6 {
      margin-top: 32px;
    }
  }
  h6 {
    @include text-lg;
    margin-bottom: 8px;
  }
}

.textWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(6);
    padding: 0 40px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 80px;
  }
}

.textContainer {
  padding: 72px 0;
  display: block;
  @include media-breakpoint-up(md) {
    padding: 80px 0 120px;
  }
}
