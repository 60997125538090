@import 'styles/import.scss';

.logos {
  display: flex;
  flex-wrap: wrap;
}

.imageWrapper {
  width: 100%;
  position: relative;

  height: 200px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  span {
    max-height: 100px;
    max-width: 175px;
    flex: 1;
  }
}

.image {
  @include make-col(6);

  &:nth-child(even) {
    :local(.imageWrapper) {
      border-right: 1px solid $grey25;
      border-bottom: 1px solid $grey25;
    }
  }
  &:nth-child(odd) {
    :local(.imageWrapper) {
      border: 1px solid $grey25;
      border-top: 0;
    }
  }
  &:nth-last-of-type(-n + 2) {
    :local(.imageWrapper) {
      border-bottom: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    @include make-col(3);
    &:nth-child(odd),
    &:nth-child(even) {
      :local(.imageWrapper) {
        // border-right: 0;
        border-left: 0;
      }
    }
    &:nth-last-of-type(-n + 4) {
      :local(.imageWrapper) {
        border-bottom: 0;
      }
    }
    &:nth-child(4n + 4) {
      :local(.imageWrapper) {
        border-right: 0;
      }
    }
  }
}
