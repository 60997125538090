@import 'styles/import.scss';

.text {
  @include heading-6;
  color: $blue_dark;
  padding: 120px 0;
  @include media-breakpoint-up(md) {
    padding: 120px;
  }
  @include media-breakpoint-up(md) {
    padding-right: 360px;
  }
}
