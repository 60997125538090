@import 'styles/import.scss';

.isHidden {
  display: none;
}

.fileWrapper {
  position: relative;
}

.file {
  position: absolute;
  opacity: 0;
  top: 0;
  &::file-selector-button {
    cursor: pointer;
  }
}

.filePlaceholder {
  height: 48px;
  width: 100%;
  @include text-md;
  color: $green_light;
  font-weight: 600;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }

  &.hasError {
    color: $red_error;
    svg * {
      stroke: $red_error;
      fill: $red_error;
    }
  }
}

.showLabel {
  @include text-xs;
  color: $grey200;
}

.container {
  @include border-underline;
  &:hover {
    label,
    label::after,
    :local(.filePlaceholder) {
      @include tn(color, 0.5s, 0s, ease-in-out);
      color: $green_dark;
    }
    svg * {
      fill: $green;
    }
  }
}
