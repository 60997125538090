@import 'styles/import.scss';

.video {
  position: relative;
  video {
    height: 100%;
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: rgba($blue_dark, 0.2);
    opacity: 1;
    top: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.5s linear;
  }

  &.isPlaying {
    :local(.icon),
    &:after {
      opacity: 0;
    }
    &:hover {
      :local(.icon) {
        opacity: 0.75;
      }
      &::after {
        opacity: 1;
      }
    }
  }
}

.icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 0.5s linear;
  &:hover {
    opacity: 1;
  }
  @include media-breakpoint-up(md) {
    bottom: 12px;
    right: 12px;
  }
  @include media-breakpoint-up(lg) {
    bottom: 16px;
    right: 16px;
  }
  @include media-breakpoint-up(xxl) {
    bottom: 20px;
    right: 20px;
  }
}

.loading {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  > div {
    width: auto;
    height: auto;
    > div {
      width: 120px;
      height: 120px;
    }
  }
}
