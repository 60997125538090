@import 'styles/import.scss';

.relatedPostsWrapper {
  overflow: hidden;
  &.noSliderButtons {
    @include media-breakpoint-up(lg) {
      .sliderWrapper {
        padding-bottom: 120px;
        padding-left: 80px;
        padding-right: 80px;
      }
      :global {
        .slick-slide {
          @include media-breakpoint-up(lg) {
            @include make-col(4, 12);
          }
        }
      }
    }
  }
  :global {
    .slick-arrow {
      width: 40px;
      height: 40px;
      bottom: -13px;
      top: calc(100% + 64px);
      z-index: 2;

      svg * {
        stroke: $blue;
      }
      &::before {
        content: '';
      }

      &:hover {
        svg * {
          stroke: $blue_light;
        }
      }

      &.slick-disabled {
        svg * {
          stroke: $grey25;
        }
      }
    }

    .slick-next {
      right: 16px;
      transform: none;
    }

    .slick-prev {
      right: 80px;
      transform: rotate(180deg);
      left: auto;
    }

    .slick-slide {
      flex-shrink: 0;
      padding: 0 10px;
      width: 300px;

      @include media-breakpoint-up(md) {
        padding: 0 12px;
        width: 320px;
      }
      @include media-breakpoint-up(lg) {
        padding: 0 16px;
        width: 460px;
      }
    }

    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex;
      width: calc(100% + 20px) !important;
      margin: 0 -10px;
      @include media-breakpoint-up(md) {
        width: calc(100% + 24px) !important;
        margin: 0 -12px;
      }
    }
  }
}

.slideImage {
  margin-bottom: 32px;
  cursor: pointer;
  height: 184px;
  > span {
    height: 100% !important;
  }
  @include media-breakpoint-up(md) {
    height: 210px;
  }
  @include media-breakpoint-up(lg) {
    height: 320px;
  }
}

.slideSubtitle {
  margin-bottom: 8px;
  color: $grey200;
  @include text-xs;
  i {
    background: $grey200;
  }
}

.slideTitle {
  color: $blue_light;
  @include tn(color, 0.5s, 0s, ease-in-out);
  @include text-xl;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:hover {
    color: $blue;
  }
}

.date {
  @include text-sm;
  margin-bottom: 8px;
  color: $blue_dark;
}

.sliderWrapper {
  padding: 80px 0 160px;

  @include media-breakpoint-up(md) {
    padding: 120px 0 292px;

    padding-left: 40px;
    padding-right: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 120px;
    padding-right: 120px;
  }
}
