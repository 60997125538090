@import 'styles/import.scss';

.wrapper {
  padding-bottom: 120px;

  @include media-breakpoint-up(lg) {
    display: flex;
    &.isHighlights {
      flex-direction: row-reverse;
      :local(.faqsWrapper) {
        border-right: 0;
        border-left: 1px solid $grey25;
      }
      :local(.faqWrapper) {
        border-right: 0;
      }
    }
  }
}

.faqsWrapper {
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    border-bottom: 1px solid $grey25;

    &.isHighlights {
      margin-left: auto;
    }
  }
}

.labelWrapper {
  border-bottom: 1px solid $grey25;
  margin: 0 -40px;
  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

.question {
  @include text-lg;
  padding-right: 24px;
  color: $blue_dark;
}
.faqWrapper {
  padding: 40px;
  margin: 0 -40px;
  border-bottom: 1px solid $grey25;
  border-left: 1px solid $grey25;

  &.isActive,
  &:hover {
    background-color: $grey1;
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 40px 80px;
    border-right: 1px solid $grey25;
    border-left: 0;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.questionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.plusIcon {
  transition: transform 0.3s ease, top 0.3s ease;
  flex-shrink: 0;

  &.isOpen {
    transform: rotate(45deg);
  }
}

.answer {
  padding-top: 40px;
  a {
    color: $blue_light;
  }

  p:has(> img) {
    img {
      width: 100%;
    }
  }
  p:not(:has(> img)) {
    max-width: 600px;
  }
}

.answerWrapper {
  opacity: 0;
  @include tn(opacity, 300ms, 800ms, ease-in);
  &.isActive {
    opacity: 1;
  }
}

.button {
  margin-top: 40px;
}

.animationWrapper {
  width: 100%;
  > div {
    height: 100%;
  }
  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid $grey25;
  }
}
