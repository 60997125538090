@import 'styles/import.scss';

.textWrapper {
  padding-top: 48px;
  padding-bottom: 80px;
  @include media-breakpoint-up(md) {
    padding: 48px 40px 80px;
  }
  @include media-breakpoint-up(lg) {
    padding: 48px 80px 80px;
  }
}

.locationsLinks {
  margin-bottom: 120px;
}

.imageWrapper {
  margin: 0 -40px;
  border-top: 1px solid $grey25;
  &:first-child {
    border-top: 0;
  }
  > span {
    max-height: 400px !important;
  }
  @include media-breakpoint-up(md) {
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    margin: 0;
    border-top: 0;
  }
}

.title {
  margin-bottom: 48px;
  @include heading-6;
  color: $blue_dark;
}

.subtitle {
  margin-bottom: 16px;
  @include text-lg;
  color: $blue_dark;
}

.address {
  @include text-md;
  color: $blue_dark;
  margin-bottom: 48px;
  p {
    margin-bottom: 0;
  }
}

.locationsWrapper {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    padding: 0 120px;
  }
}

.location {
  @include media-breakpoint-up(lg) {
    @include make-col(6);

    &:nth-child(even) {
      border-right: 1px solid $grey25;
      border-bottom: 1px solid $grey25;
    }
    &:nth-child(odd) {
      border: 1px solid $grey25;
      border-top: 0;
    }
    &:nth-child(3) {
      border-bottom: 0;
    }
  }
}

.animationWrapper {
  @include media-breakpoint-up(lg) {
    @include make-col(6);
    display: flex;
    border-right: 1px solid $grey25;
  }
}
