@import 'styles/import.scss';

.videoWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(5, 6);
    margin-left: auto;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    margin-left: auto;
  }
}
