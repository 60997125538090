@import 'styles/import.scss';

.title {
  @include heading-5;
  color: $blue_dark;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.label {
  @include text-sm;
  margin-top: 8px;
  color: $blue_dark;
}

.text {
  @include text-md;
  color: $blue_dark;
  margin-top: 64px;
  p {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.textWrapper {
  padding-top: 120px;
  &:last-child {
    padding-bottom: 120px;
  }
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.button {
  margin-top: 40px;
}

.titleLabelWrapper,
.textLinkWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
}

.titleLabelWrapper {
  @include media-breakpoint-up(md) {
    padding-left: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 80px;
  }
}

.textLinkWrapper {
  @include media-breakpoint-up(md) {
    padding-right: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding-right: 80px;
  }
}

.imageContainer {
  padding: 120px 0;
  @include media-breakpoint-up(md) {
    @include make-col(8);
    margin: 0 auto;
  }
}

.textImageWrapper {
  background: $grey1;
}
